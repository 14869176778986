.main {
  display: flex;
  flex-direction: column;
}
.button-box {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 20px;
  width: fit-content;
  text-align: center;
}

.text-box {
  display: flex;
  margin: auto;
  margin-top: 30px;
}

.recording-transcript {
  margin: 10px 10px 10px 10px;
  border: solid 2px gray;
  border-width: 1px;
  padding: 5px;
}
