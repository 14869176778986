.btn {
  margin-bottom: 10px;
  /* height: 30px; */
}

.record-btn {
  text-align: center;
  font-size: 15px;
}

.btn-upload::file-selector-button {
  width: 100px;
  height: 25px;
  background: #fff;
  border: 1px solid rgb(77, 77, 77);
  border-radius: 5px;
  cursor: pointer;
  font-size: 11;
}

.btn-label {
  width: 100px;
  height: 25px;
  background: #fff;
  border: 1px solid rgb(77, 77, 77);
  border-radius: 5px;
  cursor: pointer;
  font-size: 11;
}

.file-transcirbe-button {
  display: grid;
  grid-template-rows: 0.5fr 1fr 0.5fr;
}

.input {
  margin-right: 30px;
  height: 2em;
  width: 20em;
  font-size: medium;
}

.category {
  text-align: left;
  margin-top: 40px;
  font-size: 20px;
  font-weight: bolder;
}

.box {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding-top: 20px;
}

.textbox {
  padding-left: 10px;
}

.transcript-box {
  border: 1px solid cornflowerblue;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  text-align: center;
  min-height: 7em;
  font-size: 1.1em;
  border-radius: 10px;
  /* width: 40rem; */
}

.meta {
  padding-right: 10px;
  /* border: 1px solid cornflowerblue; */
}

.container {
  width: 60em;
  padding-bottom: 50px;
}

.transcript-text {
  padding-top: 10px;
}

.guide-list {
  font-weight: lighter;
  font-size: 0.9rem;
}

.refresh-btn {
  /* height: 20px; */
  text-align: center;
  font-size: small;
  margin-top: 5px;
  line-height: 8px;
}

span.word-color {
  color: orange;
}
