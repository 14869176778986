.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.transcript {
  min-height: 7em;
  font-size: 1.2em;
  width: 50em;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid cornflowerblue;
  align-items: center;
  text-align: center;
  /* padding: 10px 10px 10px 10px; */
}

.well {
  background-image: none;
  box-shadow: 0 0 0.5em 0.1em rgba(0, 0, 0, 0.1);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.transcribe-block {
  margin-bottom: 10px;
  /* display: grid; */
  /* grid-template-rows: 1fr 1fr 1fr 1fr; */
}

.transcript-text {
}

.guide {
  text-align: auto;
}
